@keyframes opacityFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.icons-animation {
  animation: opacityFade 1s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

#SASS {
  animation-delay: 0.6s;
}
#git {
  animation-delay: 0.7s;
}
#python {
  animation-delay: 0.9s;
}
#mongodb {
  animation-delay: 1s;
}
#firebase {
  animation-delay: 1.1s;
}

#node {
  animation-delay: 1.2s;
}
#javascript {
  animation-delay: 1.3s;
}
#react {
  animation-delay: 1.4s;
}

#CSS {
  animation-delay: 1.5s;
}
#express {
  animation-delay: 1.6s;
}
#HTML {
  animation-delay: 1.7s;
}
#next {
  animation-delay: 1.8s;
}
