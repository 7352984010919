@keyframes slideIn {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0)
  }
  10% {
    transform: translateY(-10px)
  }
  20% {
    transform: translateY(0)
  }
  30% {
    transform: translateY(-5px)
  }
  40% {
    transform: translateY(0)
  }
  100% {
    transform: translateY(0);
  }
}
