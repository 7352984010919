.projects-container {
  margin-top: 100px;
}

.projects-title {
  font-size: 5rem;
  height: 100px;
  overflow: hidden;
}

.projects-list-container {
  margin-top: 150px;
  opacity: 1;
}

.project-list {
  margin: 50px 0;
}

.projects-list-title {
  p {
    font-size: 2rem;
    &:last-of-type {
      opacity: 0.5;
      font-size: 1.5rem;
    }
  }
}

.projects-list-title-even {
  p {
    font-size: 2rem;
    &:last-of-type {
      opacity: 0.5;
      font-size: 1.5rem;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.description {
  border-radius: 20px;
  background-color: rgb(62, 62, 62);
}

.description-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  img {
    transition: all 0.2s ease-in-out;
    width: 50%;
    border-radius: 10px;
    filter: drop-shadow(30px 20px $secondary-color);
    cursor: pointer;
    &:hover {
      filter: drop-shadow(40px 30px $secondary-color);
      transform: translateY(-5px);
      transform: translateX(-5px);
    }
  }
  p {
    padding: 10px 20px;
    line-height: 150%;
    font-size: 1.1vw;
  }
}

.description-container-reversed {
  flex-direction: row-reverse;
}

.project-buttons {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  a {
    position: relative;
    text-decoration: none;
    z-index: 100;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    &:first-of-type {
      padding-left: 0px;
    }
    &:hover {
      color: $secondary-color;
    }
  }
}

.underline {
  height: 2px;
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 1%;
  transition: all 0.2s ease-in-out;
}

.links-container {
  text-align: center;
  margin-right: 15px;
  z-index: 15;
}

.links-container-code {
  position: relative;
  text-align: center;
  z-index: 15;
  svg {
    margin-right: 15px;
  }
}

.description {
  width: 40%;
  margin-top: 20px;
}

.int-animations-fade {
  animation: fadeIn 1s ease-in-out;
  animation-fill-mode: forwards;
  transition: all ease-in-out;
}

.inter-animations {
  animation: slideIn 1s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
  transition: all ease-in-out;
}
