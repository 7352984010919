@media screen and (max-width: 650px) {
  .name {
    p {
      font-size: 5vh;
    }
  }
  .title {
    p {
      font-size: 3.5vh;
    }
  }
  .intro-paragraph {
    margin-top: 5px;
    p {
      font-size: 2vh;
    }
  }
  .icons {
    margin-top: 15px;
  }
  .name-title {
    padding: 30px;
  }
  .intro-container {
    margin: 0px;
  }
  .details-container {
    div {
      font-size: 2vh;
    }
  }
  .name-title::before,
  .name-title::after {
    width: 20px;
    height: 50px;
  }
}

@media screen and (min-width: 651px) and (max-width: 1500px) {
  .name {
    p {
      font-size: 3.4rem;
    }
  }
  .title {
    p {
      font-size: 3.4rem;
    }
  }
  .intro-paragraph {
    margin-top: 5px;
    p {
      font-size: 1.5rem;
    }
  }
  .intro-container {
    height: calc(100vh - 100px);
    margin: 0px;
    transform: translateY(50px);
  }
  .details-container {
    div {
      font-size: 90%;
    }
  }
}

@media screen and (max-width: 1400px) {
  .details-container {
    display: block;
    margin-top: 10px;
    height: auto;
    overflow: hidden;
    div {
      opacity: 0;
      animation: opacity 0.3s ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 2s;
      &:nth-of-type(2) {
        margin-left: 0px;
        margin-top: 10px;
      }
      &:nth-of-type(3) {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
  .intro-buttons {
    display: none;
  }
}
