@media screen and (max-width: 1000px) {
  .description-container {
    margin-top: 20px;
    align-items: center;
    img {
      width: 100%;
      filter: none;
    }
  }
  .description {
    width: 100%;
    max-width: none;
    margin: 20px 0;
    p {
      font-size: 1.2rem;
    }
  }

  .description-container-reversed {
    flex-direction: row;
  }

  #zawn,
  #arch,
  #neverthink,
  #mozilla {
    flex-direction: column-reverse;
  }

  .projects-list-container {
    margin-top: 30px;
  }
}

@media screen and (min-width: 2200px) {
  .description-container {
    p {
      font-size: 1.5rem;
    }
  }
}
