html {
  box-sizing: border-box;
  background-color: black;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Roboto", sans-serif;
  color: white;
  scrollbar-width: none;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}

.App {
  position: relative;
}

.main-content-container {
  margin-inline: auto;
  width: min(70%, 100rem);
  margin-bottom: 50px;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .main-content-container {
    width: 90%;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 71.9px 0px 71.9px;
  padding: 50px 0 50px 0;
}

.logo {
  font-family: "Playfair Display", serif;
  overflow: hidden;
  height: 45px;
  z-index: 200;
}
.logo h1 {
  animation: slideUp 1.5s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
}

.nav-bar {
  display: flex;
  align-items: center;
  height: 20px;
  overflow: hidden;
}
.nav-bar li {
  animation: slideUp 1.5s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
  text-decoration: none;
  list-style: none;
  padding: 0 20px;
  cursor: pointer;
}
.nav-bar a {
  color: white;
  text-decoration: none;
}

.ham-menu {
  display: none;
  z-index: 200;
  height: auto;
  overflow: hidden;
}

.menu-icon {
  transform: scale(1.5);
}

:root {
  --bar-bg: white;
}

.menu-icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.menu-icon .menu-icon__cheeckbox {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
  -webkit-touch-callout: none;
  position: absolute;
  opacity: 0;
}
.menu-icon div {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 22px;
  height: 12px;
}
.menu-icon span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--bar-bg, #000);
  border-radius: 1px;
  transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}
.menu-icon span:first-of-type {
  top: 0;
}
.menu-icon span:last-of-type {
  bottom: 0;
}
.menu-icon.active span:first-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:first-of-type {
  transform: rotate(45deg);
  top: 5px;
}
.menu-icon.active span:last-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:last-of-type {
  transform: rotate(-45deg);
  bottom: 5px;
}
.menu-icon.active:hover span:first-of-type, .menu-icon.active:hover span:last-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:first-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
  width: 22px;
}
@media (min-width: 1024px) {
  .menu-icon:hover span:first-of-type {
    width: 26px;
  }
  .menu-icon:hover span:last-of-type {
    width: 12px;
  }
}

.intro-container {
  height: calc(100vh - 121.9px);
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 100px 0 100px;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
}

.name {
  overflow: hidden;
  height: auto;
  overflow: hidden;
}
.name p {
  font-size: 5rem;
  animation: slideIn 1.8s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
}

.name-title {
  transform: translateY(-15%);
  padding: 60px;
}

.name-title::before,
.name-title::after {
  opacity: 0;
  animation: opacity 1s ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;
  content: "";
  width: 50px;
  height: 100px;
}

.name-title::before {
  left: 0;
  top: 0;
  border-left: 3px solid white;
  border-top: 3px solid white;
}

.name-title::after {
  right: 0;
  bottom: 0;
  border-right: 3px solid white;
  border-bottom: 3px solid white;
}

.intro-paragraph {
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  opacity: 0.5;
  font-size: 2rem;
}
.intro-paragraph p {
  animation: slideIn 1s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
}

.details-container {
  display: flex;
  font-size: 1.4rem;
  margin-top: 40px;
  justify-content: space-between;
  position: relative;
  color: rgb(125, 125, 125);
  height: auto;
  overflow: hidden;
}
.details-container div {
  animation: slideIn 0.8s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
}
.details-container div:nth-of-type(2) {
  margin-left: 30px;
}
.details-container div:nth-of-type(3) {
  margin-left: 30px;
}
.details-container .details {
  max-width: 300px;
  display: flex;
  align-items: flex-start;
}
.details-container .details p {
  margin-top: 10px;
}
.details-container .details .bullet {
  opacity: 0;
  animation: opacity 0.3s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  top: 0;
  left: 0;
  color: white;
}

.title {
  height: auto;
  overflow: hidden;
}
.title p {
  font-size: 5rem;
  animation: slideIn 1.5s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
}

.icons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: auto;
}
.icons a {
  color: white;
  animation: slideIn 0.7s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
  transition: all 0.2s ease-in-out;
}
.icons a:hover {
  color: rgb(121, 121, 121);
}
.icons svg {
  padding-right: 10px;
  cursor: pointer;
}
.icons svg:first-of-type {
  padding-left: 0px;
}

.intro-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.intro-buttons button {
  position: absolute;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  margin: 0 10px;
  height: 100px;
  bottom: 1%;
}
.intro-buttons button:first-of-type {
  margin-left: 0px;
}

.bounce-animation {
  animation: bounce 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.arrow-fade-in {
  animation: fadeIn 1s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
}

.projects-container {
  margin-top: 100px;
}

.projects-title {
  font-size: 5rem;
  height: 100px;
  overflow: hidden;
}

.projects-list-container {
  margin-top: 150px;
  opacity: 1;
}

.project-list {
  margin: 50px 0;
}

.projects-list-title p {
  font-size: 2rem;
}
.projects-list-title p:last-of-type {
  opacity: 0.5;
  font-size: 1.5rem;
}

.projects-list-title-even {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.projects-list-title-even p {
  font-size: 2rem;
}
.projects-list-title-even p:last-of-type {
  opacity: 0.5;
  font-size: 1.5rem;
}

.description {
  border-radius: 20px;
  background-color: rgb(62, 62, 62);
}

.description-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.description-container img {
  transition: all 0.2s ease-in-out;
  width: 50%;
  border-radius: 10px;
  filter: drop-shadow(30px 20px rgb(121, 121, 121));
  cursor: pointer;
}
.description-container img:hover {
  filter: drop-shadow(40px 30px rgb(121, 121, 121));
  transform: translateY(-5px);
  transform: translateX(-5px);
}
.description-container p {
  padding: 10px 20px;
  line-height: 150%;
  font-size: 1.1vw;
}

.description-container-reversed {
  flex-direction: row-reverse;
}

.project-buttons {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.project-buttons a {
  position: relative;
  text-decoration: none;
  z-index: 100;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.project-buttons a:first-of-type {
  padding-left: 0px;
}
.project-buttons a:hover {
  color: rgb(121, 121, 121);
}

.underline {
  height: 2px;
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 1%;
  transition: all 0.2s ease-in-out;
}

.links-container {
  text-align: center;
  margin-right: 15px;
  z-index: 15;
}

.links-container-code {
  position: relative;
  text-align: center;
  z-index: 15;
}
.links-container-code svg {
  margin-right: 15px;
}

.description {
  width: 40%;
  margin-top: 20px;
}

.int-animations-fade {
  animation: fadeIn 1s ease-in-out;
  animation-fill-mode: forwards;
  transition: all ease-in-out;
}

.inter-animations {
  animation: slideIn 1s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
  transition: all ease-in-out;
}

.about-container {
  margin-top: 200px;
}

.about-title {
  font-size: 5rem;
  height: 100px;
  overflow: hidden;
}

.about-description-container {
  display: grid;
  justify-content: center;
  margin-top: 50px;
}

.about-description {
  font-size: 1.2rem;
  background-color: rgb(62, 62, 62);
  border-radius: 20px;
  opacity: 0;
  max-width: 500px;
}
.about-description p {
  padding: 20px;
  line-height: 150%;
}
.about-description a {
  transition: all 0.2s ease-in-out;
  color: rgb(121, 121, 121);
}
.about-description a:hover {
  color: white;
}

.about-icons {
  padding: 0 0 20px 20px;
}
.about-icons a {
  color: white;
  transition: all 0.2s ease-in-out;
}
.about-icons a:hover {
  color: rgb(121, 121, 121);
}
.about-icons svg {
  padding: 0 10px;
}
.about-icons svg:first-of-type {
  padding-left: 0px;
}

.grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.image-container {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: 100px 100px 100px 100px;
  gap: 10px;
}
.image-container img {
  width: 50px;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.image-border {
  border: 1px solid white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  opacity: 0;
}
.image-border p {
  font-size: 0.9rem;
}

.contact-container {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  padding-bottom: 250px;
  position: relative;
}

.contact-title {
  font-size: 5rem;
  margin-bottom: 50px;
  height: 100px;
  overflow: hidden;
}

.contact-description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 70%;
  opacity: 0;
}
.contact-description-container button:hover {
  background-color: white;
  color: black;
  transition: all 0.3s ease-in-out;
}

.contact-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 531px;
}
.contact-description p {
  text-align: center;
  line-height: 150%;
  font-size: 1.5rem;
}
.contact-description button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size: 1.5rem;
  height: 50px;
  width: 150px;
  border-radius: 5px;
  margin-top: 70px;
  cursor: pointer;
}

.contact-icons {
  margin-top: 20px;
}
.contact-icons svg {
  padding: 0 10px;
}
.contact-icons a {
  transition: all 0.2s ease-in-out;
  color: white;
}
.contact-icons a:hover {
  color: rgb(121, 121, 121);
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 1%;
  color: rgb(121, 121, 121);
}

.icons-sticky {
  position: fixed;
  z-index: 10;
}

.line {
  height: 100px;
  width: 2px;
  background-color: white;
}

@keyframes slideIn {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes slideUp {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes fadeIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-10px);
  }
  20% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-5px);
  }
  40% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes opacityFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.icons-animation {
  animation: opacityFade 1s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

#SASS {
  animation-delay: 0.6s;
}

#git {
  animation-delay: 0.7s;
}

#python {
  animation-delay: 0.9s;
}

#mongodb {
  animation-delay: 1s;
}

#firebase {
  animation-delay: 1.1s;
}

#node {
  animation-delay: 1.2s;
}

#javascript {
  animation-delay: 1.3s;
}

#react {
  animation-delay: 1.4s;
}

#CSS {
  animation-delay: 1.5s;
}

#express {
  animation-delay: 1.6s;
}

#HTML {
  animation-delay: 1.7s;
}

#next {
  animation-delay: 1.8s;
}

@media screen and (max-width: 650px) {
  .name p {
    font-size: 5vh;
  }
  .title p {
    font-size: 3.5vh;
  }
  .intro-paragraph {
    margin-top: 5px;
  }
  .intro-paragraph p {
    font-size: 2vh;
  }
  .icons {
    margin-top: 15px;
  }
  .name-title {
    padding: 30px;
  }
  .intro-container {
    margin: 0px;
  }
  .details-container div {
    font-size: 2vh;
  }
  .name-title::before,
  .name-title::after {
    width: 20px;
    height: 50px;
  }
}
@media screen and (min-width: 651px) and (max-width: 1500px) {
  .name p {
    font-size: 3.4rem;
  }
  .title p {
    font-size: 3.4rem;
  }
  .intro-paragraph {
    margin-top: 5px;
  }
  .intro-paragraph p {
    font-size: 1.5rem;
  }
  .intro-container {
    height: calc(100vh - 100px);
    margin: 0px;
    transform: translateY(50px);
  }
  .details-container div {
    font-size: 90%;
  }
}
@media screen and (max-width: 1400px) {
  .details-container {
    display: block;
    margin-top: 10px;
    height: auto;
    overflow: hidden;
  }
  .details-container div {
    opacity: 0;
    animation: opacity 0.3s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }
  .details-container div:nth-of-type(2) {
    margin-left: 0px;
    margin-top: 10px;
  }
  .details-container div:nth-of-type(3) {
    margin-left: 0px;
    margin-top: 10px;
  }
  .intro-buttons {
    display: none;
  }
}
.menu-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  z-index: 100;
}
.menu-container a {
  color: white;
  text-decoration: none;
}

.mobile-menu {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  background-color: black;
  text-align: center;
  height: 100%;
  width: 100%;
  font-size: 2rem;
}
.mobile-menu li {
  padding: 20px 0;
}

@media screen and (max-width: 900px) {
  .desk-menu {
    display: none;
  }
  .ham-menu {
    display: block;
  }
}
@media screen and (max-width: 1000px) {
  .description-container {
    margin-top: 20px;
    align-items: center;
  }
  .description-container img {
    width: 100%;
    filter: none;
  }
  .description {
    width: 100%;
    max-width: none;
    margin: 20px 0;
  }
  .description p {
    font-size: 1.2rem;
  }
  .description-container-reversed {
    flex-direction: row;
  }
  #zawn,
  #arch,
  #neverthink,
  #mozilla {
    flex-direction: column-reverse;
  }
  .projects-list-container {
    margin-top: 30px;
  }
}
@media screen and (min-width: 2200px) {
  .description-container p {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 600px) {
  .image-container {
    grid-template-columns: 150px 150px 150px;
    grid-template-rows: 150px 150px 150px 150px;
  }
  .image-container img {
    width: 80px;
  }
  .image-container p {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1000px) {
  .about-description-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .about-description {
    margin-right: 50px;
  }
  .about-description p {
    font-size: 1.5vw;
  }
  .image-container {
    grid-template-columns: 100px 100px 100px;
    grid-template-rows: 100px 100px 100px 100px;
  }
  .image-container img {
    width: 55px;
  }
  .image-container p {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 1400px) {
  .about-description-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .about-description {
    margin-right: 80px;
    max-width: 600px;
  }
  .about-description p {
    font-size: 1.4rem;
  }
  .image-container {
    grid-template-columns: 150px 150px 150px;
    grid-template-rows: 150px 150px 150px 150px;
  }
  .image-container img {
    width: 80px;
  }
  .image-container p {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1700px) {
  .about-description-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .about-description {
    margin-right: 80px;
    max-width: 600px;
  }
  .about-description p {
    font-size: 1.7rem;
  }
  .image-container {
    grid-template-columns: 175px 175px 175px;
    grid-template-rows: 175px 175px 175px 175px;
    gap: 20px;
  }
  .image-container img {
    width: 100px;
  }
  .image-container p {
    font-size: 1.2rem;
  }
}
.side-bar-container {
  opacity: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100vh;
  animation: opacity 0.3s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.left {
  position: relative;
  width: 198.45px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.61);
}
.left p {
  transform: rotate(90deg);
}
.left a {
  color: rgb(121, 121, 121);
  transition: all 0.2s ease-in-out;
}
.left a:hover {
  color: white;
  transform: translateY(-5px);
}

.right {
  width: 198.45px;
  height: 265px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.61);
}
.right p {
  transform: rotate(90deg);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.right p:hover {
  color: white;
}

.line {
  width: 1px;
  height: 150px;
  background-color: rgba(255, 255, 255, 0.61);
}

@media screen and (max-width: 1000px) {
  .side-bar-container {
    display: none;
  }
}
.work-container {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1000px;
}

.work-description-container {
  margin-top: 50px;
}

.work-description {
  max-width: 600px;
}

.work-title {
  font-size: 5rem;
  height: 100px;
  overflow: hidden;
  align-self: flex-start;
}

.work-list-title {
  margin: 30px 0 30px 0;
}
.work-list-title p {
  font-size: 2rem;
}
.work-list-title p:nth-of-type(2) {
  font-size: 1.6rem;
}
.work-list-title p:last-of-type {
  opacity: 0.5;
  font-size: 1.5rem;
}

.bullet-container {
  display: flex;
  align-items: flex-start;
  margin: 30px 0 30px 0;
}
.bullet-container:last-of-type {
  margin-bottom: 0px;
}
.bullet-container .bullet {
  transform: translateY(-9px);
  display: flex;
  align-items: center;
}

@media screen and (max-width: 750px) {
  .work-container {
    width: auto;
  }
}
.pick-border-container {
  position: relative;
  margin-top: 100px;
  padding: 60px;
  opacity: 0;
}

.pick-container::before,
.pick-container::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 100px;
}

.pick-container::before {
  left: 0;
  top: 0;
  border-left: 3px solid white;
  border-top: 3px solid white;
}

.pick-container::after {
  right: 0;
  bottom: 0;
  border-right: 3px solid white;
  border-bottom: 3px solid white;
}

.pick-container {
  display: flex;
  max-width: 1000px;
  height: auto;
}

.companies {
  position: relative;
  margin-right: 30px;
}
.companies .line {
  transition: all 0.2s ease-in-out;
  position: absolute;
  background-color: white;
  height: 82px;
  width: 2px;
}

.company-buttons {
  display: flex;
  flex-direction: column;
  border-left: 2px solid rgb(72, 72, 72);
}
.company-buttons button {
  padding: 0 20px 0 20px;
  height: 82px;
  width: 200px;
  background-color: rgb(20, 20, 20);
  transition: all 0.2s ease-in-out;
  color: white;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  cursor: pointer;
}
.company-buttons button:hover {
  background-color: rgb(50, 50, 50);
}

.opacity-animation {
  animation: opacity 0.5s forwards;
}

.content {
  opacity: 0;
}

.content-title p {
  font-size: 1.3rem;
}
.content-title p:nth-child(2) {
  margin-top: 10px;
  font-size: 1rem;
  font-family: "Courier New", Courier, monospace;
}
.content-title .company-link {
  margin-left: 5px;
  font-size: 1.3rem;
  transition: 0.2s all ease-in-out;
  color: rgb(121, 121, 121);
  border-radius: 5px;
  cursor: pointer;
}
.content-title .company-link:hover {
  color: white;
  text-decoration: underline;
}
.content-title div {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}

@media screen and (min-width: 701px) and (max-width: 900px) {
  .pick-container {
    display: block;
  }
  .line {
    display: none;
  }
  .company-buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 15px;
    border-left: none;
    justify-content: center;
    margin-bottom: 50px;
  }
  .company-buttons button {
    height: 50px;
    width: auto;
    font-size: 1rem;
    text-align: center;
  }
  .pick-border-container {
    padding: 0px;
  }
  .pick-container::before,
  .pick-container::after {
    position: static;
    width: 50px;
    height: 100px;
  }
  .pick-container::before {
    left: 0;
    top: 0;
    border-left: none;
    border-top: none;
  }
  .pick-container::after {
    right: 0;
    bottom: 0;
    border-right: none;
    border-bottom: none;
  }
}
@media screen and (max-width: 700px) {
  .companies {
    margin-right: 0px;
  }
  .pick-container {
    display: block;
  }
  .line {
    display: none;
  }
  .company-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    border-left: none;
    justify-content: center;
    margin-bottom: 50px;
  }
  .company-buttons button {
    height: 50px;
    width: auto;
    font-size: 1.2rem;
    text-align: center;
  }
  .pick-border-container {
    padding: 0px;
  }
  .pick-container::before,
  .pick-container::after {
    position: static;
    width: 50px;
    height: 100px;
  }
  .pick-container::before {
    left: 0;
    top: 0;
    border-left: none;
    border-top: none;
  }
  .pick-container::after {
    right: 0;
    bottom: 0;
    border-right: none;
    border-bottom: none;
  }
}
@media screen and (max-width: 480px) {
  .pick-border-container {
    margin-top: 30px;
  }
  .company-buttons button {
    font-size: 3.7vw;
  }
  .content-title div {
    display: block;
  }
  .content-title .company-link {
    margin: 5px 0 0 0;
  }
}/*# sourceMappingURL=App.css.map */