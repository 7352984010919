html {
  box-sizing: border-box;
  background-color: black;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Roboto", sans-serif;
  color: white;
  scrollbar-width: none;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}

.App {
  position: relative;
}

.main-content-container {
  margin-inline: auto;
  width: min(70%, 100rem);
  margin-bottom: 50px;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .main-content-container {
    width: 90%;
  }
}

@import "./components/styles/variables";
@import "./components/header/header";
@import "./components/intro/intro";
@import "./components/projects/projects";
@import "./components/about/about";
@import "./components/contact/contact";
@import "./components/sticky/email";
@import "./components/sticky/icons";
@import "./components/intro/animation";
@import "./components/about/gridanimation";
@import "./components/intro/introresponsive";
@import "./components/header/mobilemenu";
@import "./components/header/headerresponsive";
@import "./components/projects/projectresponsive";
@import "./components/about/aboutresponsive";
@import "./components/sidebar/sidebar";
@import "./components/work/work";
@import "./components/work/workresponsive";
