.intro-container {
  height: calc(100vh - 121.9px);
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 100px 0 100px;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
}

.name {
  overflow: hidden;
  height: auto;
  overflow: hidden;
  p {
    font-size: 5rem;
    animation: slideIn 1.8s cubic-bezier(0.56, -0.12, 0.31, 1.01);
    animation-fill-mode: forwards;
  }
}

.name-title {
  transform: translateY(-15%);
  padding: 60px;
}

.name-title::before,
.name-title::after {
  opacity: 0;
  animation: opacity 1s ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;
  content: "";
  width: 50px;
  height: 100px;
}

.name-title::before {
  left: 0;
  top: 0;
  border-left: 3px solid white;
  border-top: 3px solid white;
}

.name-title::after {
  right: 0;
  bottom: 0;
  border-right: 3px solid white;
  border-bottom: 3px solid white;
}

.intro-paragraph {
  height: auto;
  overflow: hidden;
  margin-top: 20px;
  opacity: 0.5;
  font-size: 2rem;
  p {
    animation: slideIn 1s cubic-bezier(0.56, -0.12, 0.31, 1.01);
    animation-fill-mode: forwards;
  }
}

.details-container {
  display: flex;
  font-size: 1.4rem;
  margin-top: 40px;
  justify-content: space-between;
  position: relative;
  color: rgb(125, 125, 125);
  height: auto;
  overflow: hidden;
  div {
    animation: slideIn .8s cubic-bezier(0.56, -0.12, 0.31, 1.01);
    animation-fill-mode: forwards;
    &:nth-of-type(2) {
      margin-left: 30px;
    }
    &:nth-of-type(3) {
      margin-left: 30px;
    }
  }
  .details {
    max-width: 300px;
    display: flex;
    align-items: flex-start;
    p {
      margin-top: 10px;
    }
    .bullet {
      opacity: 0;
      animation: opacity 0.3s ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 2s;
      top: 0;
      left: 0;
      color: white;
    }
  }
}

.title {
  height: auto;
  overflow: hidden;
  p {
    font-size: 5rem;
    animation: slideIn 1.5s cubic-bezier(0.56, -0.12, 0.31, 1.01);
    animation-fill-mode: forwards;
  }
}

.icons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: auto;
  a {
    color: white;
    animation: slideIn 0.7s cubic-bezier(0.56, -0.12, 0.31, 1.01);
    animation-fill-mode: forwards;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $secondary-color;
    }
  }
  svg {
    padding-right: 10px;
    cursor: pointer;
    &:first-of-type {
      padding-left: 0px;
    }
  }
}

.intro-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  button {
    position: absolute;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 2rem;
    margin: 0 10px;
    height: 100px;
    bottom: 1%;
    &:first-of-type {
      margin-left: 0px;
    }
  }
}

.bounce-animation {
  animation: bounce 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.arrow-fade-in {
  animation: fadeIn 1s cubic-bezier(0.56, -0.12, 0.31, 1.01);
  animation-fill-mode: forwards;
}
