.work-container {
  margin-top: $section-spacing;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1000px;
}

.work-description-container {
  margin-top: 50px;
}

.work-description {
  max-width: 600px;
}

.work-title {
  font-size: 5rem;
  height: 100px;
  overflow: hidden;
  align-self: flex-start;
}

.work-list-title {
  margin: 30px 0 30px 0;

  p {
    font-size: 2rem;
    &:nth-of-type(2) {
      font-size: 1.6rem;
    }
    &:last-of-type {
      opacity: 0.5;
      font-size: 1.5rem;
    }
  }
}

.bullet-container {
  display: flex;
  align-items: flex-start;
  margin: 30px 0 30px 0;
  &:last-of-type {
    margin-bottom: 0px;
  }
  .bullet {
    transform: translateY(-9px);
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 750px) {
  .work-container {
    width: auto;
  }
}

// custom job selector thing

.pick-border-container {
  position: relative;
  margin-top: 100px;
  padding: 60px;
  opacity: 0;
}

.pick-container::before,
.pick-container::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 100px;
}

.pick-container::before {
  left: 0;
  top: 0;
  border-left: 3px solid white;
  border-top: 3px solid white;
}

.pick-container::after {
  right: 0;
  bottom: 0;
  border-right: 3px solid white;
  border-bottom: 3px solid white;
}

.pick-container {
  display: flex;
  max-width: 1000px;
  height: auto;
}
.companies {
  position: relative;
  margin-right: 30px;
  .line {
    transition: all 0.2s ease-in-out;
    position: absolute;
    background-color: white;
    height: 82px;
    width: 2px;
  }
}

.company-buttons {
  display: flex;
  flex-direction: column;
  border-left: 2px solid $darker-secondary;
  button {
    padding: 0 20px 0 20px;
    height: 82px;
    width: 200px;
    background-color: rgb(20, 20, 20);
    transition: all 0.2s ease-in-out;
    color: white;
    border: none;
    text-align: left;
    font-size: 1.1rem;
    cursor: pointer;
    &:hover {
      background-color: rgb(50, 50, 50);
    }
  }
}

.opacity-animation {
  animation: opacity 0.5s forwards;
}

.content {
  opacity: 0;
}

.content-title {
  p {
    font-size: 1.3rem;
    &:nth-child(2) {
      margin-top: 10px;
      font-size: 1rem;
      font-family: "Courier New", Courier, monospace;
    }
  }
  .company-link {
    margin-left: 5px;
    font-size: 1.3rem;
    transition: $basic-transition;
    color: $secondary-color;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
  div {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
  }
}
