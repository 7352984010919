.side-bar-container {
  opacity: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100vh;
  animation: opacity 0.3s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.left {
  position: relative;
  width: 198.45px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.61);
  p {
    transform: rotate(90deg);
  }
  a {
    color: rgb(121, 121, 121);
    transition: all 0.2s ease-in-out;
    &:hover {
      color: white;
      transform: translateY(-5px);
    }
  }
}

.right {
  width: 198.45px;
  height: 265px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.61);
  p {
    transform: rotate(90deg);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
}

.line {
  width: 1px;
  height: 150px;
  background-color: rgba(255, 255, 255, 0.61);
}

@media screen and (max-width: 1000px) {
  .side-bar-container {
    display: none;
  }
}
