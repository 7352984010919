.icons-sticky {
  position: fixed;
  z-index: 10;
}

.line {
  height: 100px;
  width: 2px;
  background-color: white;
}
