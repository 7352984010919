.about-container {
  margin-top: $section-spacing;
}

.about-title {
  font-size: 5rem;
  height: 100px;
  overflow: hidden;
}

.about-description-container {
  display: grid;
  justify-content: center;
  margin-top: 50px;
}

.about-description {
  font-size: 1.2rem;
  background-color: rgb(62, 62, 62);
  border-radius: 20px;
  opacity: 0;
  max-width: 500px;
  p {
    padding: 20px;
    line-height: 150%;
  }
  a {
    transition: all .2s ease-in-out;
    color: $secondary-color;
    &:hover {
      color: white;
    }
  }
}

.about-icons {
  padding: 0 0 20px 20px;
  a {
    color: white;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $secondary-color;
    }
  }
  svg {
    padding: 0 10px;
    &:first-of-type {
      padding-left: 0px;
    }
  }
}

.grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.image-container {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: 100px 100px 100px 100px;
  gap: 10px;
  img {
    width: 50px;
    height: auto;
    object-fit: contain;
  }
}

.image-border {
  border: 1px solid white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  opacity: 0;
  p {
    font-size: 0.9rem;
  }
}
