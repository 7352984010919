.contact-container {
  margin-top: $section-spacing;
  display: flex;
  flex-direction: column;
  padding-bottom: 250px;
  position: relative;
}

.contact-title {
  font-size: 5rem;
  margin-bottom: 50px;
  height: 100px;
  overflow: hidden;
}

.contact-description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 70%;
  opacity: 0;
  button {
    &:hover {
      background-color: white;
      color: black;
      transition: all 0.3s ease-in-out;
    }
  }
}

.contact-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 531px;
  p {
    text-align: center;
    line-height: 150%;
    font-size: 1.5rem;
  }
  button {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    font-size: 1.5rem;
    height: 50px;
    width: 150px;
    border-radius: 5px;
    margin-top: 70px;
    cursor: pointer;
  }
}

.contact-icons {
  margin-top: 20px;
  svg {
    padding: 0 10px;
  }
  a {
    transition: all 0.2s ease-in-out;
    color: white;
    &:hover {
      color: $secondary-color;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 1%;
  color: $secondary-color;
}
