.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 71.9px 0px 71.9px;
  padding: 50px 0 50px 0;
}

.logo {
  font-family: "Playfair Display", serif;
  overflow: hidden;
  height: 45px;
  z-index: 200;
  h1 {
    animation: slideUp 1.5s cubic-bezier(0.56, -0.12, 0.31, 1.01);
    animation-fill-mode: forwards;
  }
}

.nav-bar {
  display: flex;
  align-items: center;
  height: 20px;
  overflow: hidden;
  li {
    animation: slideUp 1.5s cubic-bezier(0.56, -0.12, 0.31, 1.01);
    animation-fill-mode: forwards;
    text-decoration: none;
    list-style: none;
    padding: 0 20px;
    cursor: pointer;
  }
  a {
    color: white;
    text-decoration: none;
  }
}

.ham-menu {
  display: none;
  z-index: 200;
  height: auto;
  overflow: hidden;
}

// Hamburger

.menu-icon {
  transform: scale(1.5);
}

:root {
  --bar-bg: white;
}

.menu-icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;

  .menu-icon__cheeckbox {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    z-index: 2;
    -webkit-touch-callout: none;
    position: absolute;
    opacity: 0;
  }
  div {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 22px;
    height: 12px;
  }
  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--bar-bg, #000);
    border-radius: 1px;
    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: 0;
    }
  }
  &.active,
  .menu-icon__cheeckbox:checked + div {
    span {
      &:first-of-type {
        transform: rotate(45deg);
        top: 5px;
      }
      &:last-of-type {
        transform: rotate(-45deg);
        bottom: 5px;
      }
    }
  }

  &.active:hover span:first-of-type,
  &.active:hover span:last-of-type,
  &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
  &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
    width: 22px;
  }

  &:hover {
    // no need hover effect on mobile.
    @media (min-width: 1024px) {
      span:first-of-type {
        width: 26px;
      }

      span:last-of-type {
        width: 12px;
      }
    }
  }
}
