@media screen and (min-width: 600px) {
  .image-container {
    grid-template-columns: 150px 150px 150px;
    grid-template-rows: 150px 150px 150px 150px;
    img {
      width: 80px;
    }
    p {
      font-size: 1.2rem;
    }
  }
}

@media screen and (min-width: 1000px) {
  .about-description-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .about-description {
    margin-right: 50px;
    p {
      font-size: 1.5vw;
    }
  }

  .image-container {
    grid-template-columns: 100px 100px 100px;
    grid-template-rows: 100px 100px 100px 100px;
    img {
      width: 55px;
    }
    p {
      font-size: 0.9rem;
    }
  }
}

@media screen and (min-width: 1400px) {
  .about-description-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .about-description {
    margin-right: 80px;
    max-width: 600px;
    p {
      font-size: 1.4rem;
    }
  }

  .image-container {
    grid-template-columns: 150px 150px 150px;
    grid-template-rows: 150px 150px 150px 150px;
    img {
      width: 80px;
    }
    p {
      font-size: 1.2rem;
    }
  }
}

@media screen and (min-width: 1700px) {
  .about-description-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .about-description {
    margin-right: 80px;
    max-width: 600px;
    p {
      font-size: 1.7rem;
    }
  }

  .image-container {
    grid-template-columns: 175px 175px 175px;
    grid-template-rows: 175px 175px 175px 175px;
    gap: 20px;
    img {
      width: 100px;
    }
    p {
      font-size: 1.2rem;
    }
  }
}
